



































import Vue, { PropType } from 'vue';
import emailValidation from '@/validation/email';
import Label from '@/validation/label';

export default Vue.extend({
  name: 'FieldTags',
  props: {
    tagIcon: String,
    isEmail: Boolean,
    required: Boolean,
    errorMessage: String,
    value: Array as PropType<string[]>,
  },
  data() {
    return {
      val: '',
      labelValidation: {} as Label,
    };
  },
  methods: {
    async addTag(event: KeyboardEvent) {
      event.preventDefault();
      if (!this.val.length && this.value.length > 0 && event.key === 'Enter') {
        this.$emit('submit');
      }
      if (this.value.includes(this.val)) {
        this.val = '';
      }
      const isValid = await (this.$refs.input as any).validate();
      if (isValid && this.val && !this.value.includes(this.val)) {
        this.$emit('input', [...this.value, this.val]);
        this.val = '';
      }
    },
    removeTag(tag: string) {
      this.$emit('input', this.value.filter((t) => t !== tag));
    },
    validation(text: string): string|boolean {
      // isRequired checks if the input value or the first tag exists and is valid
      // isValid checks only the input value - the tags have already been validated
      if (text.length === 0 && this.value.length > 0) {
        // the field already exists and the user is trying to submit
        return true;
      }
      if (this.isEmail) {
        if (this.required) {
          return emailValidation.isRequired(text || this.value[0]);
        }
        return emailValidation.isValid(text);
      }

      if (this.required) {
        return this.labelValidation.isRequired(text || this.value[0]);
      }
      return this.labelValidation.isValid(text);
    },
  },
  created() {
    let name;
    if (this.$attrs.label) {
      name = this.$attrs.label;
    }
    this.labelValidation = new Label({ name });
  },
});
