import { Validation } from '@/validation/validation';

class Email extends Validation {
  private readonly emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public readonly isValidError = 'Please enter a valid email';
  public readonly isRequiredError = 'Please enter a valid email';

  isValid(email: string): boolean | string {
    if (!email) {
      return true;
    }

    if (!this.emailRegex.test(email.trim())) {
      return this.isValidError;
    }
    return true;
  }
}

export default new Email();
