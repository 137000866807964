import { SecurityLevel } from '@/types';

export type SecurityLevelData = { label: string, value: number, color: string };

export const securityLevels: Record<SecurityLevel, SecurityLevelData> = Object.freeze({
  [SecurityLevel.None]: {
    label: 'None',
    value: SecurityLevel.None,
    color: 'blue',
  },
  [SecurityLevel.Low]: {
    label: 'Low',
    value: SecurityLevel.Low,
    color: 'green',
  },
  [SecurityLevel.Medium]: {
    label: 'Medium',
    value: SecurityLevel.Medium,
    color: 'orange',
  },
  [SecurityLevel.High]: {
    label: 'High',
    value: SecurityLevel.High,
    color: 'red',
  },
});
