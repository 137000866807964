






import Vue, { PropType } from 'vue';
import { securityLevels, SecurityLevelData } from '@/util/securityLevels';
import { SecurityLevel } from '@/types';

type SecurityLevelRow = {
  securityLevel?: SecurityLevel;
  maxSecurityLevel?: SecurityLevel;
}

export default Vue.extend({
  name: 'SecurityLevelCol',
  props: {
    tag: {
      type: String,
      default: 'td',
    },
    row: {
      type: Object as PropType<SecurityLevelRow>,
      required: true,
    },
  },
  computed: {
    details(): SecurityLevelData {
      const { securityLevel, maxSecurityLevel } = this.row;

      // Some lists will display maxSecurityLevel.
      // Security level might be 0 so we need to check if it's undefined
      // to make sure there's no value
      const sl = this.row.securityLevel !== undefined ? securityLevel : maxSecurityLevel;
      const fallbackData = {
        label: 'N/A',
        color: 'black',
        value: -1,
      };

      if (sl === undefined) {
        return fallbackData;
      }
      return securityLevels[sl] || fallbackData;
    },
  },
});
