































import Vue from 'vue';
import { OrgModel } from '@/types/org';
import NewInvite from '@/views/admin/org/users/NewInvite.vue';
import { availableRoles, RoleInformation } from '@/components/admin/org/roleInformation';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';

export default Vue.extend({
  name: 'ListUsers',
  components: { SecurityLevelCol, ListWithFilter, NewInvite },
  computed: {
    searchUrl(): string {
      return `/org/${this.details.id}/user/info`;
    },
    viewUrl(): string {
      return `/manage/${this.details.id}/users`;
    },
    details(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
  },
  data() {
    return {
      roleMappings: {} as any,
      securityMappings: {} as any,
      extraColumns: [
        {
          name: 'title',
          label: 'Title',
          field: 'title',
          align: 'center',
          sortable: true,
        },
        {
          name: 'roleWithinOrg',
          label: 'Role within the Organisation',
          field: 'roleWithinOrg',
          align: 'center',
          sortable: false,
        },
        {
          name: 'securityLevel',
          label: 'Security Level',
          field: 'securityLevel',
          align: 'center',
          sortable: false,
        },
        {
          name: 'ourPeople',
          label: 'Our People',
          field: 'ourPeople',
          align: 'center',
          sortable: true,
        },
        {
          name: 'memberStatus',
          label: 'Organisational Status',
          field: 'memberStatus',
          align: 'center',
          sortable: true,
        },
        {
          name: 'isActive',
          label: 'System Status',
          field: 'isActive',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  created() {
    availableRoles.map((r: RoleInformation) => this.roleMappings[r.type] = r.name);
  },
});
