










































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import FieldTags from '@/components/fields/FieldTags.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'NewInvite',
  components: {
    FieldTags,
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: { emails: this.emails },
          method: 'post',
          url: `/org/${this.$route.params.orgId}/user/invite`,
        },
      };
    },
  },
  data() {
    return {
      emails: [] as string[],
    };
  },
  methods: {
    updateEmails(tags: string[]) {
      this.emails = tags;
    },
    async onSubmit() {
      this.error = '';

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.notify('Invitation sent');
      (this.$refs.modal as any).close();
      this.emails = [];
      this.$emit('submit');
    },
  },
});
